<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>



      </v-col>
    </v-row>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {locals} from "@/helpers/locals";

export default {
  name: 'DevPage',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      newData: null
    }
  },
  created() {
    api.post('https://tefio-vardi.bio.credit/calcularcuota', {
      data: {
        fecha: "07-10-2022",
        valor: 2000000,
        plazo: 12,
        tasa: 30.07,
        portafolio_id: 10040,
        idvardi: 309
      }
    })
    .then(response => {

      console.log('calculo sigma', response, response.data.plan_pagos[0].cuota);

    }).catch(function (error) {
      //
      console.log('errors: ', error);

      try {
        let status = error.response.status;
        if(status == 401){
          locals.clearLocal();
          this.$router.push('/');
        }
        let errors = error.response.data.errors;
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '<br>';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }

    }.bind(this))
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">


</style>
